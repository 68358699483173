export type FetchedData = [string, number, number, number, number, number, number, number, number, number, number, number]

export const stpBackup_128: FetchedData[] = [
    ["4TopN-16Ar-4", 0.9, 30, 78, 13, 97, 52, 28, 20, 60, 1.3, 20],
    ["4TopN-16TGIAr-4FHbr", 0.9, 30, 41, 18, 93, 29, 24, 47, 36, 1.14, 20],
    ["4TopN-16TGIAr-4FHgr", 0.9, 30, 37, 15, 97, 27, 21, 52, 34, 1.09, 20],
    ["4TopN-16Ar-4FHgr", 0.9, 30, 37, 15, 97, 27, 21, 52, 34, 1.09, 20],
    ["4TopN-16Ar-4FHbr", 0.9, 30, 41, 18, 93, 29, 24, 47, 36, 1.14, 20],
    ["3.3.1-14Ar-4Эл", 0.9, 35, 64, 26, 97, 37, 41, 22, 42, 1.52, 25],
    ["6-14Ar-4Эл", 0.9, 36, 64, 26, 97, 35, 41, 20, 42, 1.52, 25],
    ["4-16Ar-4Эл", 1, 30, 65, 26, 97, 40, 41, 19, 42, 1.55, 20],
    ["4TopN-14Ar-6FHcl", 0.9, 36, 59, 33, 98, 41, 38, 21, 48, 1.23, 25],
    ["4TopN-16Ar-4FHcl", 0.9, 30, 60, 34, 98, 42, 41, 17, 49, 1.22, 20],
    ["6TopN-10Ar-4.4.1", 0.7, 35, 76, 12, 96, 45, 20, 33, 53, 1.41, 35],
    ["4TopN-12Ar-4.4.1", 0.8, 36, 77, 12, 96, 47, 21, 32, 54, 1.43, 30],
    ["4TopN-14Ar-3.3.1", 0.7, 35, 78, 13, 97, 48, 28, 30, 55, 1.39, 25],
    ["4-16Ar-4Сбр", 0.9, 30, 41, 18, 93, 29, 24, 47, 36, 1.14, 20],
    ["4TopN-16-4", 0.7, 30, 79, 13, 97, 53, 28, 20, 55, 1.42, 20],
    ["4-12-4.4.1", 0.4, 36, 79, 14, 97, 62, 11, 27, 67, 1.18, 30],
    ["6-10-4.4.1", 0.3, 35, 78, 14, 96, 59, 11, 30, 67, 1.16, 35],
    ["4-14-3.3.1", 0.3, 36, 80, 14, 97, 64, 12, 24, 69, 1.16, 25],
    ["4-6-4-6-4", 0.4, 33, 75, 20, 98, 68, 18, 14, 72, 1.04, 30],
    ["4-14-6", 0.4, 36, 81, 15, 99, 74, 13, 18, 78, 1.04, 25],
    ["4-16-4", 0.4, 30, 82, 15, 99, 76, 14, 10, 80, 1.03, 20],
    ["6-14Ar-4TopN", 0.9, 36, 64, 26, 97, 39, 41, 20, 42, 1.52, 25],
    ["4TopN-14TGIAr-3.3.1", 0.7, 35, 78, 13, 97, 48, 28, 30, 55, 1.39, 25],
    ["4TopN-16TGIAr-4FHcl", 0.9, 30, 60, 34, 98, 42, 41, 17, 49, 1.22, 20],
    ["4TopN-16TGIAr-4", 0.9, 30, 78, 13, 97, 52, 28, 20, 60, 1.3, 20],
    ["6TopN-14Ar-4", 0.9, 36, 77, 13, 97, 50, 28, 22, 60, 1.28, 25],
    ["4TopN-14Ar-6", 0.9, 36, 77, 13, 97, 50, 25, 25, 59, 1.31, 25],
    ["4-16-4Эл", 0.77, 30, 65, 26, 97, 40, 41, 19, 43, 1.51, 20],
    ["4TopN-20Ar-4FHbr", 0.9, 31, 41, 18, 93, 29, 24, 47, 36, 1.14, 20],
    ["4TopN-20Ar-4FHgr", 0.9, 31, 37, 15, 97, 27, 21, 52, 34, 1.09, 20],
    ["4-20Ar-4Эл", 0.9, 31, 65, 26, 97, 40, 40, 19, 43, 1.51, 20],
    ["4TopN-20Ar-4FHcl", 0.9, 31, 60, 34, 98, 42, 41, 17, 49, 1.22, 20],
    ["4-20Ar-4Сбр", 0.9, 31, 41, 18, 93, 29, 24, 47, 36, 1.14, 20],
    ["4TopN-20-4", 0.7, 31, 79, 13, 98, 53, 28, 20, 56, 1.39, 20],
    ["4-20-4", 0.4, 31, 82, 15, 99, 76, 14, 10, 80, 1.03, 20],
    ["4TopN-20Ar-4", 0.9, 31, 78, 13, 97, 52, 28, 20, 60, 1.3, 20],
    ["4-20-4Эл", 0.8, 31, 65, 26, 97, 40, 41, 19, 43, 1.51, 20],
    ["4TopN-20TGIAr-4", 0.9, 31, 78, 13, 97, 52, 28, 20, 60, 1.3, 20],
    ["4TopN-10-4-10-4", 0.71, 33, 71, 18, 96, 46, 28, 26, 55, 1.29, 30],
    ["4-10-4-10-4Эл", 0.71, 33, 59, 30, 97, 35, 43, 22, 40, 1.48, 30],
    ["4-10-4-10Ar-4Сбр", 0.9, 33, 39, 20, 94, 37, 17, 46, 44, 1.35, 30],
    ["4-10-4-10Ar-4Эл", 0.9, 33, 59, 30, 97, 35, 36, 22, 40, 1.48, 30],
    ["4TopN-8Ar-4TopN-12Ar-4", 1.25, 33, 69, 17, 96, 39, 32, 29, 51, 1.35, 30],
    ["4TopN-10Ar-4TopN-10Ar-4", 1.25, 33, 69, 17, 96, 39, 32, 29, 50, 1.38, 30],
    ["4TopN-22Ar-6", 0.83, 36, 77, 13, 97, 50, 25, 25, 59, 1.31, 25],
    ["4TopN-24Ar-4", 0.83, 32, 78, 13, 97, 52, 28, 20, 60, 1.3, 20],
    ["6TopN-22Ar-4", 0.83, 36, 77, 13, 97, 50, 28, 22, 60, 1.28, 25],
    ["4-24-4", 0.36, 32, 81, 15, 98, 72, 13, 15, 77, 1.05, 20],
    ["4-22-6", 0.37, 36, 80, 14, 97, 70, 12, 18, 75, 1.07, 25],
    ["6TopN-9Ar-4-9-4", 0.83, 36, 70, 18, 96, 44, 27, 29, 55, 1.27, 35],
    ["4TopN-9Ar-4-9-6", 0.83, 36, 70, 18, 96, 44, 25, 31, 53, 1.32, 35],
    ["4-9-4-9-6", 0.5, 36, 73, 20, 96, 60, 16, 24, 67, 1.09, 35],
    ["4TopN-8Ar-4-12-4", 0.8, 33, 71, 18, 96, 46, 31, 26, 55, 1.29, 30],
    ["4TopN-10Ar-4-10-4", 0.83, 33, 71, 18, 96, 46, 31, 26, 55, 1.29, 30],
    ["4-8-4-12-4", 0.5, 33, 74, 20, 97, 62, 17, 21, 70, 1.07, 30],
    ["4-10-4-10-4", 0.5, 33, 74, 20, 97, 62, 17, 21, 70, 1.06, 30],
    ["4TopN-12Ar-4-12-4FHbr", 1, 33, 38, 20, 94, 26, 23, 51, 33, 1.15, 30],
    ["4TopN-12Ar-4-12-4FHgr", 1, 33, 34, 17, 96, 24, 20, 56, 31, 1.1, 30],
    ["4TopN-12TGI-4-12TGI-4FHbr", 0.77, 33, 38, 20, 94, 26, 23, 51, 34, 1.12, 30],
    ["4TopN-12TGI-4-12TGI-4FHgr", 0.77, 33, 34, 17, 96, 24, 38, 56, 31, 1.1, 30],
    ["4TopN-12Ar-4-12-4FHcl", 1, 33, 55, 37, 98, 37, 39, 24, 45, 1.21, 30],
    ["6TopN-9Ar-4-9-4.4.1", 0.83, 39, 69, 18, 95, 40, 28, 39, 49, 1.41, 45],
    ["4TopN-10Ar-4-10-4.4.1", 0.8, 37, 70, 18, 95, 41, 21, 38, 49, 1.43, 40],
    ["4TopN-10Ar-4-12-3.3.1", 0.9, 36, 70, 18, 96, 42, 22, 36, 51, 1.4, 37],
    ["4TopN-12Ar-4TopN-12Ar-4", 1.4, 33, 69, 18, 96, 40, 33, 29, 50, 1.35, 30],
    ["4-12-4-12Ar-4Сбр", 1, 33, 39, 20, 94, 37, 17, 46, 44, 1.3, 30],
    ["6-9-4-9-4.4.1", 0.5, 39, 71, 19, 95, 52, 16, 34, 60, 1.18, 45],
    ["4-10-4-10-4.4.1", 0.5, 37, 72, 19, 96, 53, 14, 31, 60, 1.2, 40],
    ["4-10-4-12-3.3.1", 0.5, 36, 73, 20, 96, 55, 15, 30, 62, 1.18, 35],
    ["4TopN-12-4-12-4", 0.77, 33, 71, 18, 96, 46, 28, 26, 55, 1.29, 30],
    ["4-12-4-12-4Эл", 0.83, 33, 59, 30, 97, 35, 43, 22, 40, 1.48, 30],
    ["6-10-4-12-4Эл", 0.83, 36, 59, 30, 96, 34, 43, 23, 40, 1.48, 35],
    ["6-10-4-12Ar-4Эл", 1, 36, 59, 30, 96, 34, 43, 23, 40, 1.48, 35],
    ["3.3.1-10-4-12Ar-4Эл", 1, 36, 59, 30, 96, 33, 43, 24, 39, 1.51, 35],
    ["4TopN-10TGIAr-4-12TGI-3.3.1", 0.9, 36, 70, 18, 96, 42, 23, 35, 51, 1.37, 35],
    ["4TopN-12TGI-4-12TGI-4FHcl", 0.8, 33, 55, 37, 98, 37, 39, 24, 45, 1.22, 30],
    ["4TopN-10Ar-4-12-6FHcl", 0.9, 36, 54, 36, 98, 36, 37, 27, 44, 1.23, 35],
    ["4TopN-10TGIAr-4-10TGI-4.4.1", 0.83, 37, 70, 18, 95, 41, 21, 38, 49, 1.43, 40],
    ["4TopN-10TGI-4-10TGI-4.4.1", 0.71, 37, 70, 18, 95, 41, 21, 38, 50, 1.4, 40],
    ["6TopN-10TGIAr-4-12TGI-4", 0.9, 36, 70, 18, 96, 44, 27, 29, 55, 1.27, 35],
    ["4TopN-10TGIAr-4-14TGI-4", 0.9, 33, 71, 18, 96, 46, 31, 26, 55, 1.29, 30],
    ["4TopN-12TGIAr-4-12TGI-4", 1, 33, 71, 18, 96, 46, 28, 26, 55, 1.29, 30],
    ["4TopN-10TGIAr-4-12TGI-6", 0.9, 36, 70, 18, 96, 44, 25, 31, 54, 1.3, 35],
    ["6TopN-10Ar-4-12-4", 0.9, 36, 70, 18, 96, 44, 27, 29, 55, 1.27, 35],
    ["4-10-4-12-6", 0.53, 36, 73, 20, 96, 60, 16, 24, 67, 1.09, 35],
    ["4TopN-10Ar-4-14-4", 0.9, 33, 71, 18, 96, 46, 28, 26, 55, 1.29, 30],
    ["4TopN-12Ar-4-12-4", 1, 33, 71, 18, 96, 46, 28, 26, 55, 1.29, 30],
    ["4-10-4-14-4", 0.53, 33, 74, 20, 97, 62, 17, 21, 70, 1.06, 30],
    ["4-12-4-12-4", 0.53, 33, 74, 20, 97, 62, 17, 21, 70, 1.06, 30],
    ["4TopN-12Ar-4-12-4.4.1", 1, 37, 70, 18, 95, 41, 21, 38, 49, 1.43, 40],
    ["4TopN-12Ar-4-14-3.3.1", 1, 36, 70, 18, 96, 42, 22, 36, 50, 1.4, 35],
    ["4TopN-14Ar-4TopN-14Ar-4", 1.7, 33, 69, 18, 96, 39, 33, 29, 50, 1.35, 30],
    ["4TopN-12Ar-4-14-6", 1, 36, 71, 18, 97, 44, 31, 31, 53, 1.32, 35],
    ["4TopN-14Ar-4-14-4", 1.1, 33, 72, 18, 97, 46, 31, 26, 55, 1.26, 30],
    ["4-12-4-12-4.4.1", 0.5, 37, 72, 19, 96, 53, 14, 31, 60, 1.2, 40],
    ["4-12-4-12-3.3.1", 0.5, 36, 73, 20, 96, 55, 15, 30, 62, 1.18, 37],
    ["4.4.1-24-4.4.1", 0.4, 41, 78, 14, 96, 56, 11, 33, 66, 1.18, 41],
    ["4-14-4-14-4", 0.6, 33, 75, 20, 98, 68, 18, 21, 73, 1.03, 30],
    ["4TopN-14Ar-4-14-4FHcl", 1.1, 33, 55, 37, 98, 37, 39, 24, 45, 1.22, 30],
    ["4TopN-14-4-14-4", 0.9, 33, 71, 18, 96, 46, 28, 26, 55, 1.29, 30],
    ["4-14-4-14-4Эл", 1, 33, 59, 30, 97, 35, 43, 22, 40, 1.48, 30],
    ["4-14-4-14Аr-4Сбр", 1.25, 33, 39, 20, 94, 37, 17, 46, 44, 1.3, 30],
    ["4TopN-14ArС-4-14С-4", 0.9, 33, 71, 18, 96, 46, 28, 26, 55, 1.29, 30],
    ["4TopN-14ArЧ-4-14Ч-4", 0.9, 33, 71, 18, 96, 46, 28, 26, 55, 1.29, 30],
    ["4-12-4-16Ar-4Эл", 1.25, 33, 59, 30, 97, 35, 43, 22, 40, 1.48, 30],
    ["4TopN-12Ar-4-16-4", 1, 33, 71, 18, 96, 46, 28, 26, 55, 1.29, 30],
    ["4-14TGI-4-14TGIAr-4Эл", 1.11, 33, 59, 30, 97, 35, 43, 22, 40, 1.48, 30],
    ["4-14-4-14Ar-4Эл", 1.11, 33, 59, 30, 97, 35, 43, 22, 40, 1.48, 30],
    ["4-12-4-14-6", 0.53, 36, 73, 20, 96, 60, 16, 24, 67, 1.09, 35],
    ["4-12TGI-4-14TGI-6", 0.53, 36, 73, 20, 96, 60, 16, 24, 67, 1.09, 35],
    ["6TopN-18Ar-4-18-6", 1.11, 36, 69, 18, 95, 43, 25, 32, 53, 1.3, 40],
    ["6TopN-18Ar-4TopN-18Ar-6", 2, 36, 67, 16, 95, 37, 29, 34, 49, 1.37, 40],
    ["6TopN-18Ar-4-18-3.3.1", 1.11, 37, 70, 18, 95, 41, 23, 36, 50, 1.4, 40],
    ["6TopN-18Ar-4-18-6FHcl", 1.11, 36, 54, 36, 97, 35, 37, 28, 44, 1.23, 40],
    ["6-18-4-18Ar-6Эл", 1.25, 36, 58, 29, 96, 34, 41, 25, 39, 1.49, 40],
    ["6-18Ar-4TopN-18Ar-6Эл", 2, 36, 56, 29, 95, 30, 41, 29, 36, 1.56, 40],
    ["6TopN-18TGIAr-4-18TGI-6", 1.11, 36, 69, 18, 95, 43, 25, 32, 53, 1.3, 40],
    ["6TopN-18TGIAr-4TopN-18TGIAr-6", 2, 36, 67, 16, 95, 37, 29, 34, 49, 1.37, 40],
    ["6TopN-18TGIAr-4-18TGI-3.3.1", 1.11, 37, 70, 18, 95, 41, 23, 36, 50, 1.4, 40],
    ["6TopN-18TGIAr-4-18TGI-6FHcl", 1, 36, 54, 36, 97, 35, 37, 28, 44, 1.23, 40],
    ["6-18TGI-4-18TGIAr-6Эл", 1.25, 36, 58, 29, 96, 34, 41, 25, 39, 1.49, 40],
    ["6-18TGIAr-4TopN-18TGIAr-6Эл", 2, 36, 56, 29, 95, 30, 41, 29, 36, 1.56, 40],
    ["6TopN-16ЧAr-4-20Ч-6", 1.11, 36, 69, 18, 95, 43, 25, 32, 53, 1.3, 40],
    ["6TopN-16СAr-4-20С-6", 1.11, 36, 69, 18, 95, 43, 25, 32, 53, 1.3, 40],
    ["6-18-6-18Ar-4Сбр", 1.25, 36, 38, 20, 95, 34, 17, 49, 43, 1.35, 40],
    ["6TopN-16Ar-6-18-6", 1.11, 35, 69, 18, 95, 42, 24, 34, 52, 1.33, 45],
    ["6TopNзак-16Ar-6зак-18-6зак", 1.11, 35, 70, 18, 96, 45, 22, 33, 55, 1.27, 45],
    ["4TopN-20Ar-4-20-4", 1.11, 33, 71, 18, 96, 46, 28, 26, 55, 1.29, 30],

]


export const stp_json = JSON.stringify(stpBackup_128)