import { StpItem } from "../TableObjects";


export const table_data_base: StpItem[] = [
    {
        name: "3.3.1-14Ar-4Эл",
        tags: ['hitproof', 'soundproof', 'multi'],
        cams: 1,
        depth: 24,
        Ro: 0.91,
        Rw: 35,
        Lt: 71,
        Lr: 13,
        Ra: 95,
        Det: 34,
        Er: 40,
        Ea: 26,
        Sf: 38,
        S: 1.87,
        weight: 27,
        secure: "CM2",
    },
    {
        name: "4-16Ar-4Эл",
        tags: ['multi'],
        cams: 1,
        depth: 24,
        Ro: 1,
        Rw: 30,
        Lt: 72,
        Lr: 13,
        Ra: 96,
        Det: 36,
        Er: 40,
        Ea: 24,
        Sf: 38,
        S: 1.89,
        weight: 20,
        secure: "нет",
    },
    {
        name: "4TopN-14Ar-6FHcl",
        tags: ['soundproof', 'solarproof', 'energy'],
        cams: 1,
        depth: 24,
        Ro: 0.91,
        Rw: 36,
        Lt: 56,
        Lr: 36,
        Ra: 96,
        Det: 39,
        Er: 41,
        Ea: 20,
        Sf: 46,
        S: 1.22,
        weight: 25,
        secure: "нет",
    },
    {
        name: "4TopN-16Ar-4FHcl",
        tags: ['solarproof', 'energy'],
        cams: 1,
        depth: 24,
        Ro: 0.91,
        Rw: 30,
        Lt: 56,
        Lr: 37,
        Ra: 96,
        Det: 40,
        Er: 43,
        Ea: 17,
        Sf: 47,
        S: 1.19,
        weight: 20,
        secure: "нет",
    },
    {
        name: "6TopN-10Ar-4.4.1",
        tags: ['hitproof', 'soundproof', 'energy'],
        cams: 1,
        depth: 24,
        Ro: 0.71,
        Rw: 35,
        Lt: 76,
        Lr: 12,
        Ra: 96,
        Det: 45,
        Er: 20,
        Ea: 35,
        Sf: 53,
        S: 1.43,
        weight: 36,
        secure: "CM3",
    },
    {
        name: "4TopN-12Ar-4.4.1",
        tags: ['hitproof', 'soundproof', 'energy'],
        cams: 1,
        depth: 24,
        Ro: 0.77,
        Rw: 36,
        Lt: 77,
        Lr: 12,
        Ra: 96,
        Det: 46,
        Er: 20,
        Ea: 34,
        Sf: 54,
        S: 1.43,
        weight: 31,
        secure: "CM3",
    },
    {
        name: "4TopN-14Ar-3.3.1",
        tags: ['hitproof', 'soundproof', 'energy'],
        cams: 1,
        depth: 24,
        Ro: 0.91,
        Rw: 35,
        Lt: 78,
        Lr: 13,
        Ra: 97,
        Det: 48,
        Er: 22,
        Ea: 30,
        Sf: 55,
        S: 1.42,
        weight: 27,
        secure: "CM2",
    },
    {
        name: "4-16Ar-4Сбр",
        tags: ['solarproof', 'multi'],
        cams: 1,
        depth: 24,
        Ro: 0.38,
        Rw: 30,
        Lt: 56,
        Lr: 9,
        Ra: 96,
        Det: 52,
        Er: 9,
        Ea: 39,
        Sf: 59,
        S: 0.95,
        weight: 20,
        secure: "нет",
    },
    {
        name: "4TopN-16-4",
        tags: ['energy'],
        cams: 1,
        depth: 24,
        Ro: 0.71,
        Rw: 30,
        Lt: 79,
        Lr: 13,
        Ra: 98,
        Det: 53,
        Er: 30,
        Ea: 17,
        Sf: 61,
        S: 1.3,
        weight: 20,
        secure: "нет",
    },
    {
        name: "4TopN-16Ar-4",
        tags: ['energy'],
        cams: 1,
        depth: 24,
        Ro: 1.1,
        Rw: 30,
        Lt: 78,
        Lr: 13,
        Ra: 97,
        Det: 52,
        Er: 28,
        Ea: 20,
        Sf: 60,
        S: 1.3,
        weight: 20,
        secure: "нет",
    },
    {
        name: "4-12-4.4.1",
        tags: ['hitproof', 'soundproof'],
        cams: 1,
        depth: 24,
        Ro: 0.36,
        Rw: 36,
        Lt: 79,
        Lr: 14,
        Ra: 97,
        Det: 62,
        Er: 11,
        Ea: 27,
        Sf: 67,
        S: 1.18,
        weight: 31,
        secure: "CM3",
    },
    {
        name: "6-10-4.4.1",
        tags: ['hitproof', 'soundproof'],
        cams: 1,
        depth: 24,
        Ro: 0.34,
        Rw: 35,
        Lt: 78,
        Lr: 14,
        Ra: 96,
        Det: 59,
        Er: 11,
        Ea: 30,
        Sf: 67,
        S: 1.16,
        weight: 36,
        secure: "CM3",
    },
    {
        name: "4-14-3.3.1",
        tags: ['hitproof', 'soundproof'],
        cams: 1,
        depth: 24,
        Ro: 0.37,
        Rw: 35,
        Lt: 80,
        Lr: 14,
        Ra: 97,
        Det: 64,
        Er: 12,
        Ea: 24,
        Sf: 69,
        S: 1.16,
        weight: 27,
        secure: "CM2",
    },
    {
        name: "4-14-6",
        tags: ['soundproof'],
        cams: 1,
        depth: 24,
        Ro: 0.36,
        Rw: 36,
        Lt: 81,
        Lr: 15,
        Ra: 99,
        Det: 74,
        Er: 13,
        Ea: 13,
        Sf: 78,
        S: 1.04,
        weight: 25,
        secure: "нет",
    },
    {
        name: "4-16-4",
        tags: ['simple'],
        cams: 1,
        depth: 24,
        Ro: 0.37,
        Rw: 30,
        Lt: 82,
        Lr: 15,
        Ra: 99,
        Det: 76,
        Er: 14,
        Ea: 10,
        Sf: 80,
        S: 1.03,
        weight: 20,
        secure: "нет",
    },
    {
        name: "4-6-4-6-4",
        tags: ['simple'],
        cams: 2,
        depth: 24,
        Ro: 0.43,
        Rw: 33,
        Lt: 75,
        Lr: 20,
        Ra: 98,
        Det: 68,
        Er: 18,
        Ea: 14,
        Sf: 72,
        S: 1.04,
        weight: 30,
        secure: "нет",
    },
    {
        name: "6-14Ar-4Эл",
        tags: ['multi', 'soundproof'],
        cams: 1,
        depth: 24,
        Ro: 1,
        Rw: 36,
        Lt: 71,
        Lr: 13,
        Ra: 95,
        Det: 35,
        Er: 40,
        Ea: 25,
        Sf: 38,
        S: 1.87,
        weight: 25,
        secure: "нет",
    },
    {
        name: "4-20Ar-4Эл",
        tags: ['multi',],
        cams: 1,
        depth: 28,
        Ro: 0.91,
        Rw: 31,
        Lt: 72,
        Lr: 13,
        Ra: 96,
        Det: 36,
        Er: 40,
        Ea: 24,
        Sf: 39,
        S: 1.85,
        weight: 20,
        secure: "нет",
    },
    {
        name: "4TopN-20Ar-4FHcl",
        tags: ['solarproof', 'energy'],
        cams: 1,
        depth: 28,
        Ro: 0.91,
        Rw: 31,
        Lt: 56,
        Lr: 37,
        Ra: 96,
        Det: 40,
        Er: 43,
        Ea: 17,
        Sf: 47,
        S: 1.19,
        weight: 20,
        secure: "нет",
    },
    {
        name: "4-20Ar-4Сбр",
        tags: ['solarproof', 'multi'],
        cams: 1,
        depth: 28,
        Ro: 0.38,
        Rw: 31,
        Lt: 56,
        Lr: 9,
        Ra: 96,
        Det: 52,
        Er: 9,
        Ea: 39,
        Sf: 59,
        S: 0.95,
        weight: 20,
        secure: "нет",
    },
    {
        name: "4TopN-20-4",
        tags: ['simple'],
        cams: 1,
        depth: 28,
        Ro: 0.71,
        Rw: 31,
        Lt: 79,
        Lr: 13,
        Ra: 98,
        Det: 53,
        Er: 30,
        Ea: 17,
        Sf: 61,
        S: 1.3,
        weight: 20,
        secure: "нет",
    },
    {
        name: "4-20-4",
        tags: ['simple'],
        cams: 1,
        depth: 28,
        Ro: 0.37,
        Rw: 31,
        Lt: 82,
        Lr: 15,
        Ra: 99,
        Det: 76,
        Er: 14,
        Ea: 10,
        Sf: 80,
        S: 1.03,
        weight: 20,
        secure: "нет",
    },
    {
        name: "3.3.1-10-4-12Ar-4Эл",
        tags: ['hitproof', 'soundproof', 'multi'],
        cams: 2,
        depth: 36,
        Ro: 1,
        Rw: 36,
        Lt: 65,
        Lr: 17,
        Ra: 94,
        Det: 30,
        Er: 41,
        Ea: 29,
        Sf: 36,
        S: 1.81,
        weight: 37,
        secure: "CM2",
    },
    {
        name: "6-10-4-12Ar-4Эл",
        tags: ['multi', 'soundproof'],
        cams: 2,
        depth: 36,
        Ro: 1,
        Rw: 36,
        Lt: 65,
        Lr: 17,
        Ra: 95,
        Det: 32,
        Er: 41,
        Ea: 27,
        Sf: 36,
        S: 1.81,
        weight: 35,
        secure: "нет",
    },
    {
        name: "4-12-4-12Ar-4Эл",
        tags: ['multi'],
        cams: 2,
        depth: 36,
        Ro: 1,
        Rw: 33,
        Lt: 66,
        Lr: 17,
        Ra: 95,
        Det: 33,
        Er: 41,
        Ea: 26,
        Sf: 36,
        S: 1.83,
        weight: 30,
        secure: "нет",
    },
    {
        name: "4TopN-12Ar-4-12-4FHcl",
        tags: ['solarproof', 'energy'],
        cams: 2,
        depth: 36,
        Ro: 1,
        Rw: 33,
        Lt: 52,
        Lr: 40,
        Ra: 97,
        Det: 35,
        Er: 42,
        Ea: 23,
        Sf: 43,
        S: 1.21,
        weight: 30,
        secure: "нет",
    },
    {
        name: "6TopN-9Ar-4-9-4.4.1",
        tags: ['hitproof', 'energy', 'soundproof'],
        cams: 2,
        depth: 36,
        Ro: 0.83,
        Rw: 39,
        Lt: 69,
        Lr: 18,
        Ra: 95,
        Det: 40,
        Er: 21,
        Ea: 39,
        Sf: 49,
        S: 1.41,
        weight: 46,
        secure: "CM3",
    },
    {
        name: "4TopN-10Ar-4-10-4.4.1",
        tags: ['hitproof', 'energy', 'soundproof'],
        cams: 2,
        depth: 36,
        Ro: 0.83,
        Rw: 37,
        Lt: 70,
        Lr: 18,
        Ra: 95,
        Det: 41,
        Er: 21,
        Ea: 38,
        Sf: 49,
        S: 1.43,
        weight: 41,
        secure: "CM3",
    },
    {
        name: "4TopN-10Ar-4-12-3.3.1",
        tags: ['hitproof', 'energy', 'soundproof'],
        cams: 2,
        depth: 36,
        Ro: 0.91,
        Rw: 36,
        Lt: 70,
        Lr: 18,
        Ra: 96,
        Det: 42,
        Er: 22,
        Ea: 36,
        Sf: 50,
        S: 1.4,
        weight: 37,
        secure: "CM2",
    },
    {
        name: "4TopN-12Ar-4TopN-12Ar-4",
        tags: ['energy'],
        cams: 2,
        depth: 36,
        Ro: 1.43,
        Rw: 33,
        Lt: 69,
        Lr: 18,
        Ra: 96,
        Det: 40,
        Er: 35,
        Ea: 25,
        Sf: 51,
        S: 1.35,
        weight: 30,
        secure: "нет",
    },
    {
        name: "4-12-4-12Ar-4Сбр",
        tags: ['solarproof', "multi"],
        cams: 2,
        depth: 36,
        Ro: 0.56,
        Rw: 33,
        Lt: 51,
        Lr: 12,
        Ra: 96,
        Det: 47,
        Er: 11,
        Ea: 42,
        Sf: 53,
        S: 0.96,
        weight: 30,
        secure: "нет",
    },
    {
        name: "6-9-4-9-4.4.1",
        tags: ['hitproof', 'soundproof'],
        cams: 2,
        depth: 36,
        Ro: 0.5,
        Rw: 39,
        Lt: 71,
        Lr: 19,
        Ra: 95,
        Det: 52,
        Er: 14,
        Ea: 34,
        Sf: 60,
        S: 1.18,
        weight: 46,
        secure: "CM3",
    },
    {
        name: "4-10-4-10-4.4.1",
        tags: ['hitproof', 'soundproof'],
        cams: 2,
        depth: 36,
        Ro: 0.5,
        Rw: 37,
        Lt: 72,
        Lr: 19,
        Ra: 96,
        Det: 53,
        Er: 14,
        Ea: 33,
        Sf: 60,
        S: 1.2,
        weight: 41,
        secure: "CM3",
    },
    {
        name: "4-10-4-12-3.3.1",
        tags: ['hitproof', 'soundproof'],
        cams: 2,
        depth: 36,
        Ro: 0.53,
        Rw: 36,
        Lt: 73,
        Lr: 20,
        Ra: 96,
        Det: 55,
        Er: 15,
        Ea: 30,
        Sf: 62,
        S: 1.18,
        weight: 37,
        secure: "CM2",
    },
    {
        name: "4.4.1-24-4.4.1",
        tags: ['hitproof', 'soundproof'],
        cams: 1,
        depth: 40,
        Ro: 0.38,
        Rw: 41,
        Lt: 78,
        Lr: 14,
        Ra: 96,
        Det: 56,
        Er: 11,
        Ea: 33,
        Sf: 66,
        S: 1.18,
        weight: 42,
        secure: "CM3",
    },
    {
        name: "4-12-4-16Ar-4Эл",
        tags: ['multi'],
        cams: 2,
        depth: 40,
        Ro: 1.25,
        Rw: 33,
        Lt: 66,
        Lr: 17,
        Ra: 95,
        Det: 32,
        Er: 41,
        Ea: 27,
        Sf: 36,
        S: 1.83,
        weight: 30,
        secure: "нет",
    },
    {
        name: "4-14-4-14Ar-4Эл",
        tags: ['multi'],
        cams: 2,
        depth: 40,
        Ro: 1.11,
        Rw: 33,
        Lt: 66,
        Lr: 17,
        Ra: 95,
        Det: 32,
        Er: 41,
        Ea: 27,
        Sf: 36,
        S: 1.83,
        weight: 30,
        secure: "нет",
    },
    {
        name: "4-14-4-14-4Эл",
        tags: ['multi'],
        cams: 2,
        depth: 40,
        Ro: 0.91,
        Rw: 33,
        Lt: 66,
        Lr: 17,
        Ra: 95,
        Det: 33,
        Er: 41,
        Ea: 26,
        Sf: 36,
        S: 1.83,
        weight: 30,
        secure: "нет",
    },
    {
        name: "4TopN-12Ar-4-12-4.4.1",
        tags: ['hitproof', 'soundproof', 'energy'],
        cams: 2,
        depth: 40,
        Ro: 1,
        Rw: 37,
        Lt: 70,
        Lr: 18,
        Ra: 95,
        Det: 41,
        Er: 21,
        Ea: 38,
        Sf: 49,
        S: 1.43,
        weight: 41,
        secure: "CM3",
    },
    {
        name: "4TopN-12Ar-4-14-3.3.1",
        tags: ['hitproof', 'soundproof', 'energy'],
        cams: 2,
        depth: 40,
        Ro: 1,
        Rw: 36,
        Lt: 70,
        Lr: 18,
        Ra: 96,
        Det: 42,
        Er: 22,
        Ea: 36,
        Sf: 50,
        S: 1.4,
        weight: 37,
        secure: "CM2",
    },
    {
        name: "4TopN-14Ar-4TopN-14Ar-4",
        tags: ['energy'],
        cams: 2,
        depth: 40,
        Ro: 1.67,
        Rw: 33,
        Lt: 69,
        Lr: 18,
        Ra: 96,
        Det: 40,
        Er: 33,
        Ea: 12,
        Sf: 51,
        S: 1.35,
        weight: 30,
        secure: "нет",
    },
    {
        name: "4TopN-12Ar-4-14-6",
        tags: ['energy', 'soundproof'],
        cams: 2,
        depth: 40,
        Ro: 1,
        Rw: 36,
        Lt: 71,
        Lr: 18,
        Ra: 97,
        Det: 47,
        Er: 29,
        Ea: 24,
        Sf: 56,
        S: 1.27,
        weight: 35,
        secure: "нет",
    },
    {
        name: "4TopN-14Ar-4-14-4",
        tags: ['energy'],
        cams: 2,
        depth: 40,
        Ro: 1.11,
        Rw: 33,
        Lt: 72,
        Lr: 19,
        Ra: 97,
        Det: 48,
        Er: 31,
        Ea: 21,
        Sf: 57,
        S: 1.26,
        weight: 30,
        secure: "нет",
    },
    {
        name: "4-12-4-12-4.4.1",
        tags: ['hitproof', 'soundproof'],
        cams: 2,
        depth: 40,
        Ro: 0.53,
        Rw: 37,
        Lt: 72,
        Lr: 19,
        Ra: 96,
        Det: 53,
        Er: 14,
        Ea: 33,
        Sf: 60,
        S: 1.2,
        weight: 41,
        secure: "CM3",
    },
    {
        name: "4-12-4-12-3.3.1",
        tags: ['hitproof', 'soundproof'],
        cams: 2,
        depth: 36,
        Ro: 0.56,
        Rw: 36,
        Lt: 73,
        Lr: 20,
        Ra: 96,
        Det: 55,
        Er: 15,
        Ea: 30,
        Sf: 62,
        S: 1.18,
        weight: 37,
        secure: "CM2",
    },
    {
        name: "4-14-4-14-4",
        tags: ['simple'],
        cams: 2,
        depth: 40,
        Ro: 0.56,
        Rw: 33,
        Lt: 75,
        Lr: 20,
        Ra: 98,
        Det: 68,
        Er: 18,
        Ea: 14,
        Sf: 73,
        S: 1.03,
        weight: 30,
        secure: "нет",
    },
    {
        name: '6TopN-18Ar-4-18-6',
        tags: ['energy', 'soundproof'],
        cams: 2,
        depth: 52,
        Ro: 1.1,
        Rw: 36,
        Lt: 69,
        Lr: 18,
        Ra: 95,
        Det: 43,
        Er: 25,
        Ea: 32,
        Sf: 53,
        S: 1.3,
        weight: 40,
        secure: 'нет',
    },
    {
        name: '6TopN-18Ar-4TopN-18Ar-6',
        cams: 2,
        depth: 52,
        tags: ['energy', 'soundproof'],
        Ro: 1.2,
        Rw: 36,
        Lt: 67,
        Lr: 16,
        Ra: 95,
        Det: 37,
        Er: 29,
        Ea: 34,
        Sf: 49,
        S: 1.4,
        weight: 40,
        secure: 'нет',
    },
    {
        name: '6TopN-18Ar-4-18-3.3.1',
        cams: 2,
        depth: 52,
        tags: ['hitproof', 'soundproof', 'energy'],
        Ro: 0.9,
        Rw: 38,
        Lt: 70,
        Lr: 18,
        Ra: 95,
        Det: 41,
        Er: 23,
        Ea: 36,
        Sf: 50,
        S: 1.4,
        weight: 40,
        secure: 'CM2',
    },
    {
        name: '6TopN-18Ar-4-18-6FHcl',
        cams: 2,
        depth: 52,
        tags: ['solarproof', 'soundproof', 'energy'],
        Ro: 0.9,
        Rw: 36,
        Lt: 54,
        Lr: 36,
        Ra: 97,
        Det: 35,
        Er: 37,
        Ea: 28,
        Sf: 44,
        S: 1.2,
        weight: 40,
        secure: "нет",
    },
    {
        name: '6-18-4-18Ar-6Эл',
        cams: 2,
        depth: 52,
        tags: ['soundproof', 'multi'],
        Ro: 1.1,
        Rw: 36,
        Lt: 58,
        Lr: 29,
        Ra: 96,
        Det: 34,
        Er: 41,
        Ea: 25,
        Sf: 39,
        S: 1.49,
        weight: 40,
        secure: "нет",
    },
    {
        name: '6-18Ar-4TopN-18Ar-6Эл',
        cams: 2,
        depth: 52,
        tags: ['soundproof', 'multi'],
        Ro: 1.3,
        Rw: 36,
        Lt: 56,
        Lr: 29,
        Ra: 95,
        Det: 30,
        Er: 41,
        Ea: 29,
        Sf: 36,
        S: 1.56,
        weight: 40,
        secure: "нет",
    },
    {
        name: '6TopN-18TgiAr-4-18Tgi-6',
        cams: 2,
        depth: 52,
        tags: ['energy', 'soundproof'],
        Ro: 1.1,
        Rw: 36,
        Lt: 69,
        Lr: 18,
        Ra: 95,
        Det: 43,
        Er: 25,
        Ea: 32,
        Sf: 53,
        S: 1.3,
        weight: 40,
        secure: "нет",
    },
    {
        name: '6TopN-18TgiAr-4TopN-18TgiAr-6',
        cams: 2,
        depth: 52,
        tags: ['energy', 'soundproof'],
        Ro: 1.2,
        Rw: 36,
        Lt: 69,
        Lr: 18,
        Ra: 95,
        Det: 37,
        Er: 29,
        Ea: 34,
        Sf: 49,
        S: 1.37,
        weight: 40,
        secure: "нет",
    },
    {
        name: '6TopN-18TgiAr-4-18Tgi-3.3.1',
        cams: 2,
        depth: 52,
        tags: ['hitproof', 'soundproof', 'energy'],
        Ro: 0.9,
        Rw: 38,
        Lt: 70,
        Lr: 18,
        Ra: 95,
        Det: 41,
        Er: 23,
        Ea: 36,
        Sf: 50,
        S: 1.4,
        weight: 40,
        secure: "CM2",
    },
    {
        name: '6TopN-18TgiAr-4-18Tgi-6FHcl',
        cams: 2,
        depth: 52,
        tags: ['solarproof', 'energy', 'soundproof'],
        Ro: 0.9,
        Rw: 36,
        Lt: 54,
        Lr: 36,
        Ra: 96,
        Det: 35,
        Er: 37,
        Ea: 28,
        Sf: 44,
        S: 1.23,
        weight: 40,
        secure: "нет",
    },
    {
        name: '6-18Tgi-4-18TgiAr-6Эл',
        cams: 2,
        depth: 52,
        tags: ['soundproof', 'multi'],
        Ro: 1.1,
        Rw: 36,
        Lt: 58,
        Lr: 29,
        Ra: 96,
        Det: 34,
        Er: 41,
        Ea: 25,
        Sf: 39,
        S: 1.49,
        weight: 40,
        secure: "нет",
    },
    {
        name: '6-18TgiAr-4TopN-18TgiAr-6Эл',
        cams: 2,
        depth: 52,
        tags: ['soundproof', 'multi'],
        Ro: 1.3,
        Rw: 36,
        Lt: 56,
        Lr: 29,
        Ra: 95,
        Det: 30,
        Er: 41,
        Ea: 29,
        Sf: 36,
        S: 1.56,
        weight: 40,
        secure: "нет",
    },
    {
        name: '6TopN-16(Ч)Ar-4-20Ч-6',
        cams: 2,
        depth: 52,
        tags: ['energy', 'soundproof'],
        Ro: 0.9,
        Rw: 36,
        Lt: 69,
        Lr: 18,
        Ra: 95,
        Det: 43,
        Er: 25,
        Ea: 32,
        Sf: 53,
        S: 1.3,
        weight: 40,
        secure: "нет",
    },
    {
        name: '6TopN-16(С)Ar-4-20С-6',
        cams: 2,
        depth: 52,
        tags: ['energy', 'soundproof'],
        Ro: 0.9,
        Rw: 36,
        Lt: 69,
        Lr: 18,
        Ra: 95,
        Det: 43,
        Er: 25,
        Ea: 32,
        Sf: 53,
        S: 1.3,
        weight: 40,
        secure: "нет",
    },
    {
        name: '6-18-6-18Ar-4Сбр',
        cams: 2,
        depth: 52,
        tags: ['multi', 'soundproof', "solarproof"],
        Ro: 0.8,
        Rw: 36,
        Lt: 50,
        Lr: 12,
        Ra: 96,
        Det: 42,
        Er: 9,
        Ea: 47,
        Sf: 51,
        S: 0.98,
        weight: 40,
        secure: "нет",
    },
    {
        name: '6TopN-16Ar-6-18-6',
        cams: 2,
        depth: 52,
        tags: ['energy', 'soundproof'],
        Ro: 1,
        Rw: 35,
        Lt: 69,
        Lr: 18,
        Ra: 95,
        Det: 42,
        Er: 24,
        Ea: 34,
        Sf: 53,
        S: 1.3,
        weight: 45,
        secure: "нет",
    },
    {
        name: '6TopN(зак)-16Ar-6(зак)-18-6(зак)',
        cams: 2,
        depth: 52,
        tags: ['hitproof', 'soundproof', 'energy'],
        Ro: 0.9,
        Rw: 35,
        Lt: 70,
        Lr: 18,
        Ra: 96,
        Det: 45,
        Er: 22,
        Ea: 33,
        Sf: 55,
        S: 1.27,
        weight: 45,
        secure: "нет",
    },
    {
        name: '4TopN-20Ar-4-20-4',
        cams: 2,
        depth: 52,
        tags: ['energy'],
        Ro: 1,
        Rw: 33,
        Lt: 71,
        Lr: 18,
        Ra: 96,
        Det: 46,
        Er: 31,
        Ea: 26,
        Sf: 55,
        S: 1.29,
        weight: 30,
        secure: "нет",
    },
];
